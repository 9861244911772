<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Update': 'Add' }} Appointment
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer mx-1"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
           </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- View Visit -->
            <b-form-group
              v-if="eventLocal.id"
              :id="`appointment-row-${eventLocal.id}-visit-operation`"
              icon="FilePlusIcon"
              @click="$router.push({ name: 'visit-operation', query: { appointment_id: eventLocal.id }})"
            >
              <template>
                <feather-icon
                  icon="FilePlusIcon"
                  class="rounded-circle d-inline-block mr-50"
                  style="color:darkorange;"
                />
                <label> Visit: </label>
                <router-link
                 :to="{ name: 'visit-operation', query: { appointment_id: eventLocal.id }}"
                 target="_blank"
                 class="m-1"
                 style="font-size: small;"
                >
                 View Visit Details
                </router-link>
                <b-tooltip
                 title="View Visit"
                 :target="`appointment-row-${eventLocal.id}-visit-operation`"
                />
              </template>
            </b-form-group>

            <!-- View Invoice -->
            <b-form-group
              v-if="eventLocal.id"
              :id="`appointment-row-${eventLocal.id}-invoice-operation`"
              icon="FilePlusIcon"
              @click="$router.push({ name: 'invoice-operation', query: { appointment_id: eventLocal.id }})"
            >
              <template>
                <feather-icon
                  icon="DollarSignIcon"
                  class="rounded-circle d-inline-block mr-50"
                  style="color:cadetblue;"
                />
                <label> Invoice: </label>
                <router-link
                 :to="{ name: 'invoice-operation', query: { appointment_id: eventLocal.id }}"
                 target="_blank"
                 class="m-1"
                 style="font-size: small;"
                >
                 View Invoice Details
                </router-link>
                <b-tooltip
                 title="View Invoice"
                 :target="`appointment-row-${eventLocal.id}-invoice-operation`"
                />
              </template>
            </b-form-group>

            <!-- Add Doctor -->
            <validation-provider
              #default="validationContext"
              name="Doctor"
            >
              <b-form-group
                label=""
                label-for="add-doctor"
                :state="getValidationState(validationContext)"
              >
                <template>
                  <feather-icon
                    icon="UserIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="color:green"
                  />
                  <label> Add Doctor </label>
                </template>
                <v-select
                  v-model="eventLocal.extendedProps.doctor"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="doctorOptions"
                  :reduce="val => val.id"
                  label="name"
                  input-id="doctor"
                >
                  <template #option="{ id, name }">
                    <feather-icon
                      icon="UserIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>

                  <template #selected-option="{ id, name }">
                    <feather-icon
                      icon="UserIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Add Patient -->
            <validation-provider
              #default="validationContext"
              name="Patient"
              rules="required"
            >
              <b-form-group
                label=""
                label-for="add-patient"
                :state="getValidationState(validationContext)"
              >
                <template>
                  <feather-icon
                    icon="UserIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="color:red"
                  />
                  <label> Add Patient </label>
                </template>
                <v-select
                  v-model="eventLocal.extendedProps.patient"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="patientOptions"
                  :reduce="val => val.id"
                  label="name"
                  input-id="patient"
                >
                  <template #option="{ id, name }">
                    <feather-icon
                      icon="UserIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>

                  <template #selected-option="{ id, name }">
                    <feather-icon
                      icon="UserIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- day -->
            <validation-provider
              #default="validationContext"
              name="Appointment Date"
              rules="required"
            >
              <b-form-group
                label="Appointment Date"
                label-for="appointment-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.extendedProps.day"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'd-m-Y' /*, minDate: 'today', maxDate: new Date().fp_incr(365)*/ }"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Time -->
            <validation-provider
              #default="validationContext"
              name="Start Time"
              rules="required"
            >
              <b-form-group
                label="Start Time"
                label-for="start-time"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K', minTime: '9:00', maxTime: '21:00' }"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Time -->
            <validation-provider
              #default="validationContext"
              name="End Time"
              rules="required"
            >
              <b-form-group
                label="End Time"
                label-for="end-time"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K', minTime: '9:00', maxTime: '21:00' }"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- ReminderMeAfter6Months -->
            <b-form-checkbox
              id="event-note"
              v-model="eventLocal.extendedProps.reminderMeAfter6Months"
              class="mb-1"
            >
             <label style="font-size: 0.857rem;">Reminder Me After 6 Months</label>
            </b-form-checkbox>

            <!-- Clinic -->
            <validation-provider
              #default="validationContext"
              name="Clinic"
            >
              <b-form-group
                label="Clinic"
                label-for="clinic"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.clinic"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clinicOptions"
                  label="value"
                  :reduce="clinic => clinic.id"
                  input-id="clinic"
                >
                  <template #option="{ id, value }">
                    <feather-icon
                      icon="MapPinIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ value }}</span>
                  </template>

                  <template #selected-option="{ id, value }">
                    <feather-icon
                      icon="MapPinIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ value }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Reason -->
            <validation-provider
              #default="validationContext"
              name="Reason"
            >
              <b-form-group
                label="Reason"
                label-for="reason"
              >
                <v-select
                  v-model="eventLocal.extendedProps.reason"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="appointmentReasonOptions"
                  label="value"
                  :reduce="reason => reason.id"
                  input-id="reason"
                >
                  <template #option="{ id, value }">
                    <feather-icon
                      icon="ClipboardIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ value }}</span>
                  </template>

                  <template #selected-option="{ id, value }">
                    <feather-icon
                      icon="ClipboardIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ value }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Note  -->
            <b-form-group
              label="Note "
              label-for="event-note"
            >
              <b-form-textarea
                id="event-note"
                v-model="eventLocal.extendedProps.note"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
            <br />
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormTextarea, BButton, BFormInvalidFeedback, BTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BTooltip,
    BFormCheckbox,

    // BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      doctorOptions,
      patientOptions,
      clinicOptions,
      appointmentReasonOptions,

      // UI
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      doctorOptions,
      patientOptions,
      clinicOptions,
      appointmentReasonOptions,
      onSubmit,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
