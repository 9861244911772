import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
    // console.log(eventLocal.value)
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))
    // console.log(eventData)
    // console.log(eventData.value.extendedProps.doctor)
    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  store.dispatch('calendar/getDoctorOptions').then().catch(() => {
    // console.log('Error fetching DoctorOptions list for dropdown')
  })
  const doctorOptions = computed(() => store.state.calendar.doctorOptions)

  store.dispatch('calendar/getPatientOptions').then().catch(() => {
    // console.log('Error fetching PatientOptions list for dropdown')
  })
  const patientOptions = computed(() => store.state.calendar.patientOptions)

  store.dispatch('calendar/getClinicOptions').then().catch(() => {
    // console.log('Error fetching ClinicOptions list for dropdown')
  })
  const clinicOptions = computed(() => store.state.calendar.clinicOptions)

  store.dispatch('calendar/getAppointmentReasonOptions').then().catch(() => {
    // console.log('Error fetching AppointmentReasonOptions list for dropdown')
  })
  const appointmentReasonOptions = computed(() => store.state.calendar.appointmentReasonOptions)

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,

    // UI
    doctorOptions,
    patientOptions,
    clinicOptions,
    appointmentReasonOptions,
    onSubmit,
  }
}
